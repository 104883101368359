import useInterval from '@Hooks/useInterval'
import RefreshIcon from '@mui/icons-material/Refresh'
import MuteIcon from '@mui/icons-material/VolumeOff'
import UnmuteIcon from '@mui/icons-material/VolumeUp'
import { CircularProgress, IconButton, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'

const TWEEN = require('@tweenjs/tween.js')

const VideoItem = (props) => {
  const {
    maxHeight = 0,
    maxWidth = 0,
    video = '',
    rarity = 1,
    color1 = '#ffffff',
    color2 = '#ffffff',
    animation = '/assets/black.png', // TODO: is this needed?
    texture = '/assets/black.png',
    mask = null,
    opacity = 100,
    onClick,
    shadow = false,
    controls = false,
    disableAnimation = false,
  } = props

  const [c1, setC1] = useState(color1)
  const [c2, setC2] = useState(color2)
  const [animationUrl, setAnimationUrl] = useState(animation)
  const [textureUrl, setTextureUrl] = useState(texture)
  const [cardStyle, setCardStyle] = useState({})
  const [animationTween, setAnimationTween] = useState(null)
  const [animationPosition, setAnimationPosition] = useState('')
  const [hovering, setHovering] = useState(false)
  const [animating, setAnimating] = useState(false)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [loading, setLoading] = useState(true)

  const videoRef = useRef(null)
  const [muted, setMuted] = useState(true)

  const getDimensions = () => {
    if (Number.isNaN(maxWidth) || Number.isNaN(maxHeight)) return
    const width = 1920
    const height = 1080
    const ratioW = width / height
    const ratioH = height / width
    if (maxWidth === 0 || ratioW < ratioH) {
      setWidth(maxHeight * ratioW)
      setHeight(maxHeight)
    } else {
      setWidth(maxWidth)
      setHeight(maxWidth * ratioH)
    }
    setLoading(false)
  }

  useInterval(() => {
    if (hovering) return

    const tween = new TWEEN.Tween({ x: 100, y: 100 })
      .to({ x: 0, y: 0 }, 750)
      .easing(TWEEN.Easing.Quadratic.In)
      .onUpdate((changes) => {
        setAnimationPosition(`${changes.x}% ${changes.y}%`)
      })
      .onComplete(() => {
        tween.stop()
        setAnimationTween(null)
        setAnimationPosition('')
        setAnimating(false)
      })

    tween.start()
    setAnimationTween(tween)
    setAnimating(true)
  }, 5000 + Math.random() * (10 - rarity) * 1000)

  useInterval(
    () => {
      if (animationTween) animationTween.update()
    },
    animating ? 50 : null
  )

  useEffect(() => {
    getDimensions()
    // eslint-disable-next-line
  }, [maxWidth, maxHeight])

  useEffect(() => {
    getDimensions()
    setGraphics()
    // eslint-disable-next-line
  }, [rarity, animation, texture, color1, color2, mask, video])

  useEffect(() => {
    getDimensions()
    return () => {
      stopAnimationTween()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!videoRef.current) return
    if (disableAnimation) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }, [disableAnimation, videoRef])

  const setGraphics = () => {
    if (rarity === 5) {
      setAnimationUrl(animation ? `url(${animation})` : '')
      setTextureUrl(texture ? `url(${texture})` : '')
      setC1(color1)
      setC2(color2)
    } else if (rarity === 4) {
      setAnimationUrl('url(/assets/black.png)')
      setTextureUrl(texture ? `url(${texture})` : '')
      setC1(color1 + 'cc')
      setC2(color2 + '99')
    } else if (rarity === 3) {
      setAnimationUrl('url(/assets/black.png)')
      setTextureUrl(``)
      setC1(color1 + 'aa')
      setC2(color2 + '66')
    } else if (rarity === 2) {
      setAnimationUrl('url(/assets/black.png)')
      setTextureUrl(``)
      setC1('#ffffff99')
      setC2('#ffffff44')
    } else if (rarity === 1) {
      setAnimationUrl('url(/assets/black.png)')
      setTextureUrl(``)
      setC1('#ffffff11')
      setC2('#ffffff00')
    }
    setCardStyle({})
  }

  const stopAnimationTween = () => {
    if (!animationTween) return
    animationTween.stop()
    setAnimationTween(null)
  }

  const handleOver = () => {
    stopAnimationTween()
    setHovering(true)
  }

  const handleOut = (e) => {
    setCardStyle({})
    setHovering(false)
  }

  const handleHover = (e) => {
    e.preventDefault()
    const pos = [e.nativeEvent.offsetX, e.nativeEvent.offsetY]
    handleTilt(pos[0], pos[1])
  }

  const handleTouch = (e) => {
    if (!hovering) {
      stopAnimationTween()
      setHovering(true)
    }

    const bcr = e.target.getBoundingClientRect()
    const x = e.targetTouches[0].clientX - bcr.x
    const y = e.targetTouches[0].clientY - bcr.y

    const pos = [x, y]
    handleTilt(pos[0], pos[1])
    return false
  }

  const handleTilt = (l, t) => {
    // math for mouse pos
    const h = height
    const w = width
    const px = Math.min(100, Math.abs(Math.floor((100 / w) * l) - 100))
    const py = Math.min(100, Math.abs(Math.floor((100 / h) * t) - 100))
    const pa = 50 - px + (50 - py)
    // math for gradient / background positions
    const lp = 50 + (px - 50) / 1.5
    const tp = 50 + (py - 50) / 1.5
    const pxSpark = 50 + (px - 50) / 7
    const pySpark = 50 + (py - 50) / 7
    const pOpc = 20 + Math.abs(pa) * 1.5
    const ty = ((tp - 50) / 2) * -1
    const tx = ((lp - 50) / 1.5) * 1

    setCardStyle({
      transform: `rotateX(${ty}deg) rotateY(${tx}deg)`,
    })

    document.documentElement.style.setProperty(
      '--gradient-pos',
      `${lp}% ${tp}%`
    )
    document.documentElement.style.setProperty(
      '--sparkle-pos',
      `${pxSpark}% ${pySpark}%`
    )
    document.documentElement.style.setProperty(
      '--sparkle-opacity',
      `${pOpc / (rarity === 5 ? 100 : 300)}`
    )
    setAnimationPosition('')
  }

  const muteClick = () => {
    setMuted(!muted)
  }

  const restartClick = () => {
    videoRef.current.currentTime = 0
  }

  const glowMod = (height / 400) * (1 - (5 - rarity) / 5)

  const hoverGlowCss = {
    boxShadow: `-${20 * glowMod}px
    -${20 * glowMod}px
    ${20 * glowMod}px
    -${20 * glowMod}px ${c1},
    ${20 * glowMod}px
    ${20 * glowMod}px
    ${20 * glowMod}px
    -${20 * glowMod}px ${c2},
    -7px -7px 10px -5px ${c1},
    7px 7px 10px -5px ${c2},
    0 0 13px 4px rgba(255, 255, 255, 0.3),
    0 55px 35px -20px rgba(0, 0, 0, 0.5) !important`,
  }

  if (loading)
    return (
      <Box width={width} height={height} sx={{ position: 'relative' }}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={width}
          height={height}
          sx={{ borderRadius: `5% / ${(width / height) * 5}% !important` }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(50% - 24px)',
            left: 'calc(50% - 24px)',
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </Box>
    )

  return (
    <Box sx={{ height: `${height}px`, width: `${width}px` }}>
      <Box
        sx={{
          height: `${height}px`,
          width: `${width}px`,
          transform: 'translate3d(0.1px, 0.1px, 0.1px)',
          perspective: '2000px',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {rarity >= 4 && animationPosition !== '' && (
          <Box
            sx={{
              zIndex: 20,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              pointerEvents: 'none',
              borderRadius: `5% / ${(width / height) * 5}% !important`,
              mixBlendMode: 'color-dodge',
            }}
          >
            <Box
              style={{
                width: '100%',
                height: '100%',
                animation: 'none',
                backgroundImage: `linear-gradient(110deg, transparent 25%, ${c1} 48%, ${c2} 52%, transparent 75%)`,
                backgroundSize: '250% 250%',
                opacity: 0.88,
                filter: 'brightness(0.66) contrast(1.33)',
                transition: 'none',
                backgroundPosition: animationPosition,
                pointerEvents: 'none',
              }}
            />
          </Box>
        )}
        <Box
          className="card"
          width={width}
          height={height}
          style={cardStyle}
          sx={{
            borderRadius: `5% / ${(width / height) * 5}% !important`,
            boxShadow:
              !hovering && shadow
                ? '0 55px 35px -20px rgba(0, 0, 0, 0.5) !important'
                : undefined,
            '&:hover': {
              ...hoverGlowCss,
            },
            '&:before': {
              backgroundImage: `linear-gradient(115deg, transparent 0%, ${c1} 25%, transparent 47%, transparent 53%, ${c2} 75%, transparent 100%) !important`,
              ...(mask ? { mask: `url(${mask})`, maskSize: '100% 100%' } : {}),
            },
            '&:after': {
              opacity: `${opacity / 100} !important`,
              backgroundImage: `${animationUrl}, ${textureUrl}, linear-gradient(125deg, #ff008450 15%, #fca40040 30%, #ffff0030 40%, #00ff8a20 60%, #00cfff40 70%, #cc4cfa50 85%) !important`,
              backgroundSize: `cover, cover, cover !important`,
              ...(mask ? { mask: `url(${mask})`, maskSize: '100% 100%' } : {}),
            },
            '&:hover:before': {
              backgroundImage: `linear-gradient(110deg, transparent 25%, ${c1} 48%, ${c2} 52%, transparent 75%) !important`,
            },
          }}
          onMouseMove={handleHover}
          onTouchMove={handleTouch}
          onMouseOver={handleOver}
          onMouseOut={handleOut}
          onTouchEnd={handleOut}
          onTouchCancel={handleOut}
        >
          <video
            ref={videoRef}
            width={width}
            height={height}
            autoPlay
            loop
            muted={muted}
          >
            <source src={video} type="video/mp4" />
          </video>
        </Box>
      </Box>
      {controls && (
        <Box display="flex" justifyContent="center" sx={{ width }}>
          <IconButton
            onClick={restartClick}
            sx={{ opacity: 0.5, '&:hover': { opacity: 1 } }}
          >
            <RefreshIcon />
          </IconButton>
          <IconButton
            onClick={muteClick}
            sx={{ opacity: 0.5, '&:hover': { opacity: 1 } }}
          >
            {!muted ? <UnmuteIcon /> : <MuteIcon />}
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default VideoItem
