const preloadImages = (urls, callback) => {
  let counter = 0
  urls.forEach(function (url) {
    preloadImage(url, () => {
      counter++
      if (counter === urls.length) callback()
    })
  })
}

const preloadImage = (url, callback) => {
  const img = new Image()
  img.onload = callback
  img.src = url
}

export default preloadImages
